import React from "react";
import { Post } from "../../../../stores/CommunityStore/posts/types";

interface Props {
  trendingPosts: Post[];
}
const TrendingPosts: React.FC<Props> = ({ trendingPosts }) => {
  return (
    <div className="ui community__sidebar-topics">
      <div className="community__sidebar-topics__header">
        <h4 className="font-18">Top Trending 🔥</h4>
      </div>
      <hr />
      <div className="community__sidebar-trending-posts">
        {trendingPosts.map((post) => (
          <React.Fragment key={post.uuid}>
            <div className="community__sidebar-trending-post">
              <p dangerouslySetInnerHTML={{ __html: post.formatted_content }} />
              <div>
                <span>
                  <strong>
                    Details <i className="ui icon arrow right"></i>
                  </strong>
                </span>
                <span className="community__sidebar-trending-post__topic">
                  # {post.topic_name}
                </span>
              </div>
            </div>
            <hr />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default TrendingPosts;
