import { createStore, combineReducers, applyMiddleware } from "redux";
import { useDispatch } from "react-redux";
import { thunk, ThunkAction, ThunkDispatch } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { PostActionTypes } from "./posts/types";
import { CommentActionTypes } from "./comments/types";
import { postReducer } from "./posts/postReducer";
import { commentReducer } from "./comments/commentReducer";
import { topicReducer } from "./topics/topicReducer";
import { trendingPostReducer } from "./trendingPosts/trendingPostReducer";

const rootReducer = combineReducers({
  posts: postReducer,
  comments: commentReducer,
  topics: topicReducer,
  trendingPosts: trendingPostReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

// For async actions, define ThunkAction type
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  PostActionTypes | CommentActionTypes
>;

// @ts-ignore
export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk)),
);

export type AppDispatch = ThunkDispatch<
  AppState,
  void,
  PostActionTypes | CommentActionTypes
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
