import { postRequest } from "../../../../utils/httpRequest";
import { AxiosError, AxiosResponse } from "axios";
import {
  CREATE_COMMENT_SUCCESS,
  CREATE_COMMENT_FAILURE,
  REPLY_COMMENT_SUCCESS,
} from "../types";

const createCommentSuccess = (data) => ({
  type: CREATE_COMMENT_SUCCESS,
  postId: data.postId,
  comment: data.comment,
});

const createCommentReplySuccess = (data) => ({
  type: REPLY_COMMENT_SUCCESS,
  postId: data.postId,
  parentCommentId: data.commentId,
  comment: data.comment,
});

const createCommentFailure = (error) => ({
  type: CREATE_COMMENT_FAILURE,
  payload: error,
});

type AddCommentProps = {
  postId?: number;
  commentId?: number;
  content: string;
};

// Thunk Action Creator for API Call
export const createComment =
  ({ postId, commentId, content }: AddCommentProps) =>
  async (dispatch) => {
    console.log("postId", postId);
    console.log("content", content);
    postRequest("/api/community/comments.json", {
      post_id: postId,
      comment_id: commentId,
      community_comment: {
        content,
      },
    })
      .then((response: AxiosResponse) => {
        if (commentId) {
          dispatch(
            createCommentReplySuccess({
              postId,
              commentId,
              comment: response.data,
            }),
          );
        } else {
          dispatch(createCommentSuccess({ postId, comment: response.data }));
        }
      })
      .catch((error: AxiosError<{ error: string }>) => {
        console.error(error);
        // dispatch(createPostFailure(error.response?.data.error));
      });
  };
