import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Topic } from "../../../../stores/CommunityStore/topics/types";
import { fetchPosts } from "../../../../stores/CommunityStore/posts/actions/fetchPosts";
``;
interface TopicsProps {
  topics: Topic[];
}

const Topics: React.FC<TopicsProps> = ({ topics }) => {
  const dispatch = useDispatch();
  const [activeTopic, setActiveTopic] = useState("");

  const fetchTopicPosts = (e: any, topicUuid: string) => {
    e.preventDefault();

    setActiveTopic(topicUuid);

    dispatch({ type: "SET_TOPIC", topicUuid });
    dispatch(fetchPosts({ topicUuid }));
  };

  return (
    <div className="ui community__sidebar-topics">
      <div className="community__sidebar-topics__header">
        <h4 className="font-18">All Topics</h4>
      </div>
      <hr />
      <div className="community__sidebar-topics__buttons">
        <div className="community__sidebar-topics__button">
          <button
            className={`ui button ${activeTopic ? "" : "active"}`}
            onClick={(e) => fetchTopicPosts(e, "")}
          >
            # General Chat
          </button>
        </div>
        {topics.map((topic) => (
          <div key={topic.uuid} className="community__sidebar-topics__button">
            <button
              className={`ui button ${activeTopic === topic.uuid ? "active" : ""}`}
              onClick={(e) => fetchTopicPosts(e, topic.uuid)}
            >
              # {topic.name}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Topics;
