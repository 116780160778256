import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ReplyCommentForm from "../components/ReplyCommentForm";
import Reply from "../components/Reply";
import { CurrentUser } from "../../../../../../stores/CurrentUserStore/Types";
import {
  LikeIcon,
  CommentIcon,
  ReplyIcon,
  EmptyCommentIcon,
  UnLikeIcon,
  DeleteIcon,
} from "../../../../Icon";
import {
  likeComment,
  unlikeComment,
} from "../../../../../../stores/CommunityStore/comments/actions/likeComment";
import { Comment as CommentType } from "../../../../../../stores/CommunityStore/comments/types";
import { deleteComment } from "../../../../../../stores/CommunityStore/comments/actions/deleteComment";
import { deletePost } from "../../../../../../stores/CommunityStore/posts/actions/deletePost";

interface Props {
  currentUser: CurrentUser;
  postId: number;
  comment: CommentType;
}

const Comment: React.FC<Props> = ({ currentUser, postId, comment }) => {
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [showReplies, setShowReplies] = useState(false);

  const dispatch = useDispatch();

  const toggleShowReplies = (e) => {
    e.preventDefault();

    setShowReplies(!showReplies);
  };

  const onClickLikeComment = (e) => {
    e.preventDefault();
    dispatch(
      likeComment({
        postId: postId,
        commentId: comment.id,
      }),
    );
  };

  const onClickUnLikeComment = (e) => {
    e.preventDefault();
    dispatch(
      unlikeComment({
        postId: postId,
        commentId: comment.id,
      }),
    );
  };

  const toggleReplyForm = (e) => {
    e.preventDefault();
    setShowReplyForm(true);
  };

  const onClickDeleteComment = (e) => {
    e.preventDefault();

    let text = "Are you sure you want to delete this comment?";

    if (confirm(text) == true) {
      dispatch(
        deleteComment({
          postId,
          commentId: comment.id,
        }),
      );
    } else {
      return;
    }
  };

  return (
    <div className="ui community__comments-comment">
      <div className="community__comment-container">
        <div className="community__feed-profile">
          <img
            className=""
            src={comment.user?.profile_picture_url}
            alt="profile picture"
          />
        </div>
        <div className="community__comments-post">
          <span>
            <strong>{comment.user?.name}</strong>
            <span className="community__comment-time">
              .&nbsp;&nbsp;the other day
            </span>
          </span>
          <div
            className="community__comments-content"
            dangerouslySetInnerHTML={{ __html: comment.formatted_content }}
          />
          <div className="community__feed-reactions">
            {comment.liked_by_current_user ? (
              <a href="#" onClick={(e) => onClickUnLikeComment(e)}>
                <span className="community__feed-reactions-like">
                  <UnLikeIcon />
                  <span>{comment.likes}</span>
                </span>
              </a>
            ) : (
              <a href="#" onClick={(e) => onClickLikeComment(e)}>
                <span className="community__feed-reactions-like">
                  <LikeIcon />
                  <span>{comment.likes}</span>
                </span>
              </a>
            )}
            <a href="#" onClick={(e) => toggleShowReplies(e)}>
              <span className="community__feed-reactions-comment">
                {comment.replies.length === 0 ? (
                  <EmptyCommentIcon />
                ) : (
                  <CommentIcon />
                )}
                <span>{comment.replies.length}</span>
              </span>
            </a>
            <a href="#" onClick={(e) => toggleReplyForm(e)}>
              <span className="community__feed-reactions-comment">
                <ReplyIcon />
                <span></span>
              </span>
            </a>
            {comment.replies.length > 0 && (
              <>
                {(currentUser.id === comment?.user?.id ||
                  currentUser.admin) && (
                  <a href="#" onClick={(e) => onClickDeleteComment(e)}>
                    <span className="community__feed-reactions-comment">
                      <DeleteIcon />
                      <span></span>
                    </span>
                  </a>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showReplies && (
        <>
          {comment.replies.map((reply) => (
            <Reply
              key={reply.uuid}
              currentUser={currentUser}
              postId={postId}
              commentId={comment.id}
              reply={reply}
              toggleReplyForm={toggleReplyForm}
            />
          ))}
        </>
      )}
      {showReplyForm && (
        <ReplyCommentForm
          currentUser={currentUser}
          commentId={comment.id}
          postId={postId}
          setShowReplies={setShowReplies}
        />
      )}
    </div>
  );
};

export default Comment;
