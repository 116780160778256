import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CurrentUser } from "../../../../../../stores/CurrentUserStore/Types";
import { createComment } from "../../../../../../stores/CommunityStore/comments/actions/createComment";
import { AppState } from "../../../../../../stores/CommunityStore";

interface Props {
  currentUser: CurrentUser;
  postId: number;
}

const AddComment: React.FC<Props> = ({ currentUser, postId }) => {
  const dispatch = useDispatch();
  const created = useSelector((state: AppState) => state.comments.created);

  const [text, setText] = useState("");
  const [rows, setRows] = useState(1);

  useEffect(() => {
    if (created) {
      setText("");
      dispatch({ type: "RESET_COMMENT_CREATED" });
    }
  }, [postId, created]);

  const handleChange = (event) => {
    const currentText = event.target.value;
    setText(currentText);

    // Calculate the number of rows needed
    const lineBreaks = (currentText.match(/\n/g) || []).length + 1;
    setRows(lineBreaks);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(
      createComment({
        postId,
        content: text,
      }),
    );
  };

  return (
    <div className="ui community__add-comment">
      <div className="ui form">
        <div className="field">
          <div className="profile">
            <img
              className="ui avatar image"
              src={currentUser.profile_picture}
              alt="profile picture"
            />
          </div>
          <div className="ui input">
            <textarea
              className="form-control"
              placeholder="Write your comment here."
              value={text}
              onChange={handleChange}
              rows={rows}
              style={{ resize: "none", overflow: "hidden" }}
            />
          </div>
        </div>
        <div className="actions">
          <button className="ui button" onClick={(e) => onSubmit(e)}>
            Comment
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddComment;
