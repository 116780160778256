import React, { Component } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppState, AppDispatch } from "../../stores/CommunityStore";
import {
  fetchPosts,
  fetchMorePosts,
} from "../../stores/CommunityStore/posts/actions/fetchPosts";
import { fetchTopics } from "../../stores/CommunityStore/topics/actions/fetchTopics";
import AddPost from "./components/Feeds/AddPost";
import Feeds from "./components/Feeds";
import Topics from "./components/Sidebar/Topics";
import TrendingPosts from "./components/Sidebar/TrendingPosts";
import MobileSidebar from "./components/Sidebar/MobileSidebar";
import { CurrentUser } from "../../stores/CurrentUserStore/Types";
import { DesktopIcon, MobileIcon } from "./Icon";
import { Post } from "../../stores/CommunityStore/posts/types";
import { Topic } from "../../stores/CommunityStore/topics/types";
import { fetchTrendingPosts } from "../../stores/CommunityStore/trendingPosts/actions/fetchTrendingPosts";

interface CommunityProps {
  posts: Post[];
  topics: Topic[];
  trendingPosts: Post[];
  loading: boolean;
  dispatch: AppDispatch;
  currentUser: CurrentUser;
  fetchPosts: (params) => void;
  fetchTopics: () => void;
  fetchTrendingPosts: () => void;
  fetchMorePosts: (page: number) => void;
}

interface CommunityState {
  showSidebar: boolean;
  page: number;
}

class Community extends Component<CommunityProps, CommunityState> {
  constructor(props: CommunityProps) {
    super(props);
    this.state = {
      showSidebar: false,
      page: 1,
    };
  }

  componentDidMount() {
    this.props.fetchTopics();
    this.props.fetchPosts({});
    this.props.fetchTrendingPosts();
  }

  fetchMoreData = () => {
    this.setState({ page: this.state.page + 1 });
    this.props.fetchMorePosts(this.state.page);
  };

  handleShowSidebar = (
    e: React.MouseEvent<HTMLAnchorElement>,
    value: boolean,
  ) => {
    e.preventDefault();
    this.setState({ showSidebar: value });
  };

  render() {
    const { loading, currentUser, posts, topics, trendingPosts } = this.props;
    const { showSidebar } = this.state;

    return (
      <div className="ui pages" id="community">
        <div className="">
          <div className="mobile topic-trigger">
            <a href="#" onClick={(e) => this.handleShowSidebar(e, true)}>
              <span style={{ color: "#36CBBD" }}>#</span>{" "}
              <span style={{ color: "#1E293B" }}>
                General Chat
                <i className="icon angle down"></i>
              </span>
            </a>
          </div>
          <div className="ui community__hero" style={{ background: "#392D40" }}>
            <h1 style={{ color: "#fff" }}>WHAZZZUUUUUP?</h1>
          </div>
          <div className="ui segment">
            <div className="ui grid stackable community__body">
              <div
                className="ui four wide column desktop"
                style={{ paddingTop: "0px" }}
              >
                <div className="community__sidebar">
                  <Topics topics={topics} />
                  <br />
                  <TrendingPosts trendingPosts={trendingPosts} />
                </div>
              </div>
              <div
                className="ui twelve wide column community__feed-body"
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                <div className="community__feed-header">
                  <div className="community__feed-header--info">
                    <DesktopIcon />
                    <MobileIcon />
                    <span className="title">Community</span>
                  </div>
                  <a className="community__feed-header--search">
                    <i className="search icon"></i>
                  </a>
                </div>
                <hr />
                <AddPost currentUser={currentUser} />
                <InfiniteScroll
                  dataLength={posts.length}
                  next={this.fetchMoreData}
                  style={{ display: "flex", flexDirection: "column-reverse" }}
                  inverse={true} //
                  hasMore={true}
                  loader={<></>}
                  scrollableTarget="scrollableDiv"
                >
                  <Feeds currentUser={currentUser} posts={posts} />
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile">
          <MobileSidebar
            showSidebar={showSidebar}
            handleHideSidebar={this.handleShowSidebar}
            topics={topics}
            trendingPosts={trendingPosts}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  posts: state.posts.posts,
  topics: state.topics.topics,
  trendingPosts: state.trendingPosts.trendingPosts,
  loading: state.posts.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPosts: (params) => dispatch(fetchPosts(params)),
  fetchTopics: () => dispatch(fetchTopics()),
  fetchTrendingPosts: () => dispatch(fetchTrendingPosts()),
  fetchMorePosts: (page) => dispatch(fetchMorePosts(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Community);
