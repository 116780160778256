import React, { useState } from "react";
import Feed from "../Feed";
import { CurrentUser } from "../../../../stores/CurrentUserStore/Types";
import { Post } from "../../../../stores/CommunityStore/posts/types";
import { useDispatch } from "react-redux";
import { fetchPosts } from "../../../../stores/CommunityStore/posts/actions/fetchPosts";
import { Dropdown } from "semantic-ui-react";

interface Props {
  currentUser: CurrentUser;
  posts: Post[];
}

const Feeds: React.FC<Props> = ({ currentUser, posts }) => {
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState<string | null>(null);

  const onClickSortOption = (e, data, mode) => {
    e.preventDefault();

    setSortBy(data.text);
    dispatch(
      fetchPosts({
        sortBy: mode,
      }),
    );
  };

  return (
    <div className="ui community__feeds">
      <div className="community__feeds-divider">
        <hr />
        <span />
        <div className="community__feeds-divider--sort">
          Sort by:{" "}
          <Dropdown
            floating
            inline
            direction="left"
            text={sortBy ? sortBy : ""}
            className="courses-sort-dropdown"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                text="Date"
                onClick={(e, data) => onClickSortOption(e, data, "date")}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <>
        {posts.map((post) => (
          <React.Fragment key={post.id}>
            <Feed post={post} currentUser={currentUser} />
            {posts.length > 0 && <hr />}
            <br />
          </React.Fragment>
        ))}
      </>
    </div>
  );
};

export default Feeds;
