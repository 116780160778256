import React from "react";
import AddComment from "./components/AddComment";
import Comment from "./components/Comment";
import { CurrentUser } from "../../../../../stores/CurrentUserStore/Types";
import { Post } from "../../../../../stores/CommunityStore/posts/types";

interface Props {
  currentUser: CurrentUser;
  post: Post;
}

const Comments: React.FC<Props> = ({ currentUser, post }) => {
  return (
    <div className="ui community__comments">
      <h3>{post.comments.length} Comments</h3>
      <AddComment currentUser={currentUser} postId={post.id} />
      {post.comments.map((comment) => (
        <>
          <Comment
            key={comment.id}
            postId={post.id}
            currentUser={currentUser}
            comment={comment}
          />
        </>
      ))}
    </div>
  );
};

export default Comments;
