// types.ts
import {
  Comment,
  CreateCommentSuccessAction,
  ReplyCommentSuccessAction,
  LikeCommentAction,
  UnLikeCommentAction,
  LikeCommentReplyAction,
  UnLikeCommentReplyAction,
  DeleteCommentSuccessAction,
  DeleteCommentReplySuccessAction,
} from "../comments/types";

export interface Post {
  id: number;
  uuid: string;
  formatted_content: string;
  likes: number;
  comments_count: number;
  topic_name: string;
  pinned: boolean;
  trending: boolean;
  user: {
    id: number;
    name: string;
    profile_picture_url: string;
  };
  comments: Comment[];
  liked_by_current_user: boolean;
}

export interface PostState {
  posts: Post[];
  loading: boolean;
  created: boolean;
  error: string;
}

export const LOAD_POSTS = "LOAD_POSTS";
export const LOAD_POSTS_SUCCESS = "LOAD_POSTS_SUCCESS";
export const LIKE_POST = "LIKE_POST";
export const UNLIKE_POST = "UNLIKE_POST";
export const LOAD_POSTS_FAILURE = "LOAD_POSTS_FAILURE";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_FAILURE = "CREATE_POST_FAILURE";
export const RESET_POST_CREATED = "RESET_POST_CREATED";
export const LIKE_POST_SUCCESS = "LIKE_POST_SUCCESS";
export const LIKE_POST_FAILURE = "LIKE_POST_FAILURE";
export const UNLIKE_POST_SUCCESS = "UNLIKE_POST_SUCCESS";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";
export const PIN_POST_SUCCESS = "PIN_POST_SUCCESS";
export const PIN_POST_FAILURE = "PIN_POST_FAILURE";
export const LOAD_MORE_POSTS = "LOAD_MORE_POSTS";
export const LOAD_MORE_POSTS_SUCCESS = "LOAD_MORE_POSTS_SUCCESS";
export const LOAD_MORE_POSTS_FAILURE = "LOAD_MORE_POSTS_FAILURE";
export const FEATURE_POST = "FEATURE_POST";

export interface LoadPostsAction {
  type: typeof LOAD_POSTS;
}

export interface LoadPostsSuccessAction {
  type: typeof LOAD_POSTS_SUCCESS;
  payload: Post[];
}

export interface LikePostAction {
  type: typeof LIKE_POST;
  postId: number;
}

export interface UnLikePostAction {
  type: typeof UNLIKE_POST;
  postId: number;
}

export interface LoadPostsFailureAction {
  type: typeof LOAD_POSTS_FAILURE;
  payload: string;
}

export interface CreatePostSuccessAction {
  type: typeof CREATE_POST_SUCCESS;
  payload: Post;
}

export interface ResetPostCreatedAction {
  type: typeof RESET_POST_CREATED;
}

export interface LikePostSuccessAction {
  type: typeof LIKE_POST_SUCCESS;
}

export interface LikePostFailureAction {
  type: typeof LIKE_POST_FAILURE;
}

export interface UnLikePostSuccessAction {
  type: typeof LIKE_POST_FAILURE;
}

export interface DeletePostSuccessAction {
  type: typeof DELETE_POST_SUCCESS;
  postId: number;
}

export interface DeletePostFailureAction {
  type: typeof DELETE_POST_FAILURE;
}

export interface PinPostSuccessAction {
  type: typeof PIN_POST_SUCCESS;
  postId: number;
}

export interface PinPostFailureAction {
  type: typeof PIN_POST_FAILURE;
}

export interface LoadMorePostsAction {
  type: typeof LOAD_MORE_POSTS;
}

export interface LoadMorePostsSuccessAction {
  type: typeof LOAD_MORE_POSTS_SUCCESS;
  payload: Post[];
}

export interface LoadMorePostsFailureAction {
  type: typeof LOAD_MORE_POSTS_FAILURE;
  payload: string;
}

export interface FeaturePostAction {
  type: typeof FEATURE_POST;
  postId: number;
  trending: boolean;
}

export type PostActionTypes =
  | LoadPostsAction
  | LoadPostsSuccessAction
  | LikePostAction
  | UnLikePostAction
  | LoadPostsFailureAction
  | CreatePostSuccessAction
  | ResetPostCreatedAction
  | LikePostSuccessAction
  | LikePostFailureAction
  | UnLikePostSuccessAction
  | CreateCommentSuccessAction
  | ReplyCommentSuccessAction
  | LikeCommentAction
  | UnLikeCommentAction
  | LikeCommentReplyAction
  | UnLikeCommentReplyAction
  | DeletePostSuccessAction
  | DeletePostFailureAction
  | PinPostSuccessAction
  | PinPostFailureAction
  | LoadMorePostsAction
  | LoadMorePostsSuccessAction
  | LoadMorePostsFailureAction
  | FeaturePostAction
  | DeleteCommentSuccessAction
  | DeleteCommentReplySuccessAction;
