import React from "react";
import { useDispatch } from "react-redux";
import { DeleteIcon, LikeIcon, ReplyIcon, UnLikeIcon } from "../../../../Icon";
import { CurrentUser } from "../../../../../../stores/CurrentUserStore/Types";
import { Reply as ReplyType } from "../../../../../../stores/CommunityStore/comments/types";
import { deleteComment } from "../../../../../../stores/CommunityStore/comments/actions/deleteComment";
import {
  likeComment,
  unlikeComment,
} from "../../../../../../stores/CommunityStore/comments/actions/likeComment";

interface Props {
  currentUser: CurrentUser;
  postId: number;
  commentId: number;
  reply: ReplyType;
  toggleReplyForm: (e) => void;
}

const Reply: React.FC<Props> = ({
  currentUser,
  postId,
  commentId,
  reply,
  toggleReplyForm,
}) => {
  const dispatch = useDispatch();

  const onClickLikeReplyComment = (e) => {
    e.preventDefault();
    dispatch(
      likeComment({
        postId,
        commentId,
        replyId: reply.id,
      }),
    );
  };

  const onClickUnLikeReplyComment = (e) => {
    e.preventDefault();
    dispatch(
      unlikeComment({
        postId,
        commentId,
        replyId: reply.id,
      }),
    );
  };

  const onClickDeleteCommentReply = (e) => {
    e.preventDefault();

    let text = "Are you sure you want to delete this comment?";

    if (confirm(text) == true) {
      dispatch(
        deleteComment({
          postId,
          commentId: commentId,
          replyId: reply.id,
        }),
      );
    } else {
      return;
    }
  };

  return (
    <>
      <div key={reply.id} className="community__comment-container replies">
        <div className="community__feed-profile">
          <img
            className=""
            src={reply.user?.profile_picture_url}
            alt="profile picture"
          />
        </div>
        <div key={reply.uuid} className="community__comments-post">
          <span>
            <strong>{reply.user?.name}</strong>
            <span className="community__comment-time">
              .&nbsp;&nbsp;the other day
            </span>
          </span>
          <div className="community__comments-content">
            <p
              dangerouslySetInnerHTML={{
                __html: reply.formatted_content,
              }}
            />
          </div>
          <div className="community__feed-reactions">
            {reply.liked_by_current_user ? (
              <a href="#" onClick={(e) => onClickUnLikeReplyComment(e)}>
                <span className="community__feed-reactions-like">
                  <UnLikeIcon />
                  <span>{reply.likes}</span>
                </span>
              </a>
            ) : (
              <a href="#" onClick={(e) => onClickLikeReplyComment(e)}>
                <span className="community__feed-reactions-like">
                  <LikeIcon />
                  <span>{reply.likes}</span>
                </span>
              </a>
            )}
            <a href="#" onClick={(e) => toggleReplyForm(e)}>
              <span className="community__feed-reactions-comment">
                <ReplyIcon />
                <span></span>
              </span>
            </a>
            {(currentUser.id === reply?.user?.id || currentUser.admin) && (
              <a href="#" onClick={(e) => onClickDeleteCommentReply(e)}>
                <span className="community__feed-reactions-comment">
                  <DeleteIcon />
                  <span></span>
                </span>
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Reply;
