import { deleteRequest, postRequest } from "../../../../utils/httpRequest";
import { AxiosError, AxiosResponse } from "axios";
import {
  LIKE_COMMENT_SUCCESS,
  LIKE_COMMENT_FAILURE,
  LIKE_COMMENT,
} from "../types";

const likeCommentSuccess = () => ({
  type: LIKE_COMMENT_SUCCESS,
});

const likeCommentFailure = (error) => ({
  type: LIKE_COMMENT_FAILURE,
  payload: error,
});

type LikeCommentProps = {
  postId: number;
  commentId: number;
  replyId?: number;
};

export const likeComment =
  ({ postId, commentId, replyId }: LikeCommentProps) =>
  async (dispatch) => {
    if (replyId) {
      dispatch({ type: "LIKE_COMMENT_REPLY", postId, commentId, replyId });
    } else {
      dispatch({ type: "LIKE_COMMENT", postId, commentId });
    }

    postRequest("/api/community/likes.json", {
      comment_id: replyId ? replyId : commentId,
    })
      .then((response: AxiosResponse) => {
        dispatch(likeCommentSuccess());
      })
      .catch((error: AxiosError<{ error: string }>) => {
        console.error(error);
        // dispatch(createPostFailure(error.response?.data.error));
      });
  };

export const unlikeComment =
  ({ postId, commentId, replyId }: LikeCommentProps) =>
  async (dispatch) => {
    if (replyId) {
      dispatch({ type: "UNLIKE_COMMENT_REPLY", postId, commentId, replyId });
    } else {
      dispatch({ type: "UNLIKE_COMMENT", postId, commentId });
    }

    deleteRequest(
      `/api/community/likes/unlike.json?comment_id=${replyId ? replyId : commentId}`,
    )
      .then((response: AxiosResponse) => {})
      .catch((error: AxiosError<{ error: string }>) => {
        console.error(error);
        // dispatch(createPostFailure(error.response?.data.error));
      });
  };
